import axios from 'axios';
import CryptoJS from 'crypto-js';

// Generate a nonce (random string for each request)
const generateNonce = () => {
  return Math.random().toString(36).substring(2, 15);
};

// Generate a timestamp (current time in seconds)
const generateTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

// Create the signature base string
const generateBaseString = (method, url, params) => {
  const sortedParams = Object.keys(params)
    .sort()
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return `${method.toUpperCase()}&${encodeURIComponent(url)}&${encodeURIComponent(sortedParams)}`;
};

// Generate HMAC-SHA1 signature
const generateSignature = (baseString, consumerSecret, tokenSecret = '') => {
  const signingKey = `${encodeURIComponent(consumerSecret)}&${encodeURIComponent(tokenSecret)}`;
  const signature = CryptoJS.HmacSHA1(baseString, signingKey);
  return CryptoJS.enc.Base64.stringify(signature);
};
const createOAuthHeader = (method, url, consumerKey, consumerSecret, token = '', tokenSecret = '') => {
    const oauthParams = {
      oauth_consumer_key: consumerKey,
      oauth_nonce: generateNonce(),
      oauth_signature_method: 'HMAC-SHA1',
      oauth_timestamp: generateTimestamp(),
      oauth_token: token,
      oauth_version: '1.0',
    };
  
    // Generate the base string for the signature
    const baseString = generateBaseString(method, url, oauthParams);
  
    // Generate the signature
    const oauthSignature = generateSignature(baseString, consumerSecret, tokenSecret);
  
    // Add signature to oauthParams
    oauthParams.oauth_signature = oauthSignature;
  
    // Build the Authorization header
    const authHeader = `OAuth ${Object.keys(oauthParams)
      .map(key => `${encodeURIComponent(key)}="${encodeURIComponent(oauthParams[key])}"`)
      .join(', ')}`;
  
    return authHeader;
  };


  export const createWooCommerceOrder = async (siteurl,key, scerect, payload, message) => {

    const method = 'POST';
    const url = siteurl+'/wp-json/wc/v3/orders';
    const consumerKey = key;//'ck_e7f01c197cf2332f0409f4c2ce9111acbb099f5a';
    const consumerSecret = scerect;//'cs_689181c65f79eb020d6647ed7cf73f6ff8de6220'; 
  
    // Create the OAuth 1.0 Authorization header
    const authHeader = createOAuthHeader(method, url, consumerKey, consumerSecret);

    
  
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
      //console.log('Order Created:', response.data);
    } catch (error) {
      console.error('Error creating order:', error.response?.data || error.message);
    }
  };
  export const createShopifyCommerceOrder = async (siteurl, token, payload) => {
    const url = `${siteurl}/admin/api/2024-10/orders.json`;
  
    try {
      const response = await axios.post(url, payload, {
        headers: {
          'X-Shopify-Access-Token': token,
          'Content-Type': 'application/json',
        },
      });
     // console.log('Order Created:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating order:', error.response?.data || error.message);
      throw error; // Rethrow the error for further handling
    }
  };
    
  