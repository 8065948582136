import React from "react";

function Payments(){
    const [paymentStatus, setPaymentStatus] = useState(null);
    const handlePaymentSuccess = (response) => {
        console.log(response);
        // Example response from PhonePe after payment success
        const paymentResponse = {
          transactionId: response.transactionId,
          paymentAmount: response.amount,
          paymentDate: new Date(),
          status: 'SUCCESS',
        };
    }
    return (
        <div>Payement Call BAck</div>
    );
}
export default Payments;