import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import { postData, vpostData, getData, orderPostData } from '../Services/Services';
import {settingGetData, checkoutpostData } from '../Services/Seting';
import { paymentPostData } from '../Services/Payment';
import Config from "../Config/Config";
import axios from "axios";

import OTPInput from "./OTPInput";
const display1={
    display: 'none1'
}

function Checkout(){
    const [currentStep, setCurrentStep] = useState(1);
    const [step, setStep] = useState(1);
    const location = useLocation(); // Provides access to the current URL
    const queryParams = new URLSearchParams(location.search);
    //const getorder=queryParams.get('cart');
    const getorder=queryParams.get('userDeviceId');
   
    const [storeKey, setStoreKey] = useState('');
    const [storeSKey, setStoreSKey] = useState('');
    const [products, setProducts] = useState('');
    const [cartamount, setCartAmount] = useState('');
    const [display, setDisplay] = useState('block');
    const [storeId, setStoreid] =useState(1);
    const [merchantId, setMerchantid] =useState(1);
    const [storeConfigData, setStoreConfig] =useState('');
    const [cartitems, setCartitems] = useState('');
    const [links, setLink] =useState('');
   
    // Define the progress width based on the current step
    useEffect(() => {
                console.log(getorder);
                // if (getorder && getorder.trim() !== '') {
                //     const orderdetails= atob(getorder);
                
                //     const storedata=JSON.parse(orderdetails);
                //     setProducts(storedata.products);
                //     setCartAmount(storedata.total_amount);
                //     const datacart=JSON.stringify(storedata.products)
                //     setCartitems(storedata.products);
                //    // localStorage.setItem('cartitems', datacart);
                    
                
            
                //     const sArraydata= storedata.store_info;
            
                //     sArraydata.forEach(( sArraydata, index) => {
                //         setStoreKey(sArraydata.consumer_key);
                //         setStoreSKey(sArraydata.consumer_secret);
                        
                //     });
                //     const configSetup =async()=>{
                       
                //             const url = `${Config.SETTING_BASE_URL}/${Config.SETTING_CONFIG}`+storeSKey;
                           
                //                 try{
                //                     const response = await settingGetData(url, "MObile_send_otp");
                                   
                //                     if (response.status === true || response.code === 200) {
                //                         const storeDatas=response.data.rows;
                //                         var storeSettinglink = '';
                //                         storeDatas.forEach(( storeData, index) => {
                                         
                //                             setStoreid(storeData.store_id)
                //                             setMerchantid(storeData.merchant_id)
                                           
                //                             storeSettinglink = storeData.configuration;
                //                             setStoreConfig(storeSettinglink)

                                             
                //                         });
                //                         const settings = JSON.parse(storeSettinglink);
                //                         setLink(settings.general_settings.links);
                                        
                                       
                //                     }
                                    
                //                 }catch(error){
                //                     //setMessage(error.message + ' Failed Please try again.');
                //                 }
                                        
                //     };
                  
                //     configSetup();
                // }
                
      
    },[location.search]);
    
    const progress = {
      width: `${(currentStep - 1) * 50}%`,  // Adjust progress width based on the step
      transition: 'width 0.5s ease-in-out',  // Smooth transition
    };
  
    // Move to the next step
    const nextStep = () => {
     
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    };
    // State to hold the quantity value
  const [quantity, setQuantity] = useState(1);

  // Function to handle increment
  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  // Function to handle decrement
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  // Function to handle direct input change
  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1) {
      setQuantity(value);
    } else {
      setQuantity(1); // Minimum quantity is 1
    }
  };
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumber: '',
});
const [mobile, setMobile] = useState('');
const [phone , setPhone]  =useState('');
// State for form validation messages
const [errors, setErrors] = useState({});
const [error, SetError] = useState('');

// Handle input change
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); // Update the specific field
    setIsValid(true)
};

// Validate form data
const validateForm = () => {
    let validationErrors = {};

    // Simple phone number validation (check if it's empty or invalid)
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!formData.phoneNumber) {
        validationErrors.phoneNumber = "Phone number is required.";
    } else if (!phoneRegex.test(formData.phoneNumber)) {
        validationErrors.phoneNumber = "Please enter a valid 10-digit phone number.";
    }
    return validationErrors;
};

  const mobileNumberSubmit= async (e) => {
    e.preventDefault();
   
    const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            setMobile(formData.phoneNumber);
            setPhone(formData.phoneNumber)
         
            // Form is valid, submit the form
           // console.log('Form data submitted:', formData);
            const payload ={
                "mobile_no": formData.phoneNumber,
                "services": [
                    "sms"
                ],
                "type":"",
                "event_type": "otp"
             }
             setStep(2);
            try{
                const response = await postData(`${Config.BASE_URL}/${Config.SEND_OTP}`, payload, "MObile_send_otp");
                if (response.status === true || response.code === 200) {
                    localStorage.setItem('pii', response.data.pii_id);
                    setStep(2);
                }else{
                    SetError('Something Runtime Error!');
                }
            }catch(error){
                SetError(error.message);
            }
          
        } else {
            // Form has validation errors
            setErrors(validationErrors);
        }
  }

  const getLastFourDigits = (mobile) => {
    // Check if the input has enough digits and extract the last 4 digits
    return mobile.length >= 4 ? mobile.slice(-4) : mobile;
  }
  const [otp, setOtp] = useState(''); // State to store OTP input

  const [request, setRequest]= useState('');

  const handleOtpChange = (value) => {
    setOtp(value);
  };
  const optVerify = async (event) => {
    event.preventDefault();
    
    if (otp.length !== 6) {
        SetError("OTP must be 6 digits.");
        return;
      }
     
      try {
        const payload={
            'pii_id': localStorage.getItem('pii'),
            'otp': parseInt(otp)
        };
        const response = await vpostData(`${Config.VARIFYOTP_BASE_URL}/${Config.VARIFYOTP}`, payload, "MObile_send_otp");
       
        if (response.status === 200) {
            localStorage.setItem('phone', mobile);
            localStorage.setItem('pii', response.pii_id);
             const filter='phone';
             const endurl= `${Config.BASE_URL}/${Config.GET_ADDRESS}?${filter}=${mobile}`;
            
            const getAddress=await getData(endurl, 'Featch Address');
            if (getAddress.status === true || getAddress.code === 200) {
                if(getAddress.data.length > 0){
                localStorage.setItem('preaddress',  JSON.stringify(getAddress.data));
               const setRequest={
                        "storeid": storeId,
                        "merchant_id": merchantId,
                        //"channel_id":2,
                        "status":"pending",
                        "checkout_configrations":{
                            "item_details":cartitems,
                            "user_type":"old user"
                        },
                    };
                    const checkout = await checkoutpostData(`${Config.SETTING_BASE_URL}/${Config.SETTING_CHECKOUT}`, setRequest, "CHECKOUT");
                }else{
                     setRequest={
                        "storeid": storeId,
                        "merchant_id": merchantId,
                        //"channel_id":2,
                        "status":"pending",
                        "checkout_configrations":{
                            "item_details":cartitems,
                            "user_type":"new user"
                        },
                        
                    };
                    const checkout = await checkoutpostData(`${Config.SETTING_BASE_URL}/${Config.SETTING_CHECKOUT}`, setRequest, "CHECKOUT");
                }

               setDisplay('none');
             setStep(3);
             nextStep()
             setCurrentStep(currentStep + 1);
        
            }else{
                SetError('Something Runtime Error!');
            }

      
        }else{
            SetError('Something Runtime Error!');
        }
     
      
      
   
      } catch (error) {
        //throw error;
        SetError('Invalid OTP');
       // console.log(error.message);
      }
    
};
//Address
const addressList = JSON.parse(localStorage.getItem('preaddress'));
const [customername, setCustomerName ]= useState('');
const [customeraddress1, setCustomerAddress1 ]= useState('');
const [customeraddress2, setCustomerAddress2 ]= useState('');
const [customercity, setCustomerCity ]= useState('');
const [customerstate, setCustomerState ]= useState('');
const [customerzip, setCustomerZip ]= useState('');
const [customerphone, setCustomerPhone]= useState('');
const selectAddress = async(event)=> {
    const seladdress = JSON.parse(event.target.value);

     setFormData({
        pid:seladdress.pid,
        name:seladdress.name,
        email:seladdress.email,
        zip: seladdress.zip,
        city: seladdress.city,
        state: seladdress.state,
        address_1: seladdress.address_1,
        address_2: seladdress.address_2,
        phone: seladdress.phone,
        address_type: seladdress.address_type,

     })
     setCustomerName(seladdress.name);
     setCustomerAddress1(seladdress.address_1)
     setCustomerAddress2(seladdress.address_2)
     setCustomerCity(seladdress.city)
     setCustomerState(seladdress.state)
     setCustomerZip(seladdress.zip)
     setCustomerPhone(mobile)

     setStep(4);
     nextStep()
     setCurrentStep(currentStep + 1);
  }

  const [isNewAddress, setNewAddress] = useState(false);
  const [hasValidAddress, sethasValidAddress] =useState(true)
  const [formAddessData, setAddressFormData] = useState({
    name: customername ? customername : '',
    phone:`${localStorage.getItem('phone')}`, 
    email: '',
    zip: '',
    city: '',
    state: '',
    address_1: '',
    address_2: '',
   // phone: customer_phone,
    address_type: 'home',
  });
  const addNewAddess=()=>{

    setNewAddress(true);
    sethasValidAddress(false)
  }

  const handleAddressChange = (e) =>{
    const { name, value } = e.target;
    setAddressFormData({
      ...formAddessData,
      [name]: value, // Update the respective field's value
    });
   
   
  
};
const validateAddressForm = () => {
    let validationErrors = {};
    if (!formAddessData.name) validationErrors.name = "Full Name is required.";
    if (!formAddessData.email) validationErrors.email = "Email is required.";
    if (!/^\S+@\S+\.\S+$/.test(formAddessData.email)) validationErrors.email = "Email format is invalid.";
    if (!formAddessData.zip) validationErrors.zip = "Pincode is required.";
    if (!formAddessData.city) validationErrors.city = "City is required.";
    if (!formAddessData.state) validationErrors.state = "State is required.";
    if (!formAddessData.address_1) validationErrors.address_1 = "Address 1 is required.";

    return validationErrors;
  };
const handleAddressSubmit = async(e) => {
    e.preventDefault(); // Prevent page reload
    const validationErrors = validateAddressForm();
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        setErrors({});
       // console.log("Form data submitted:", formAddessData);
        try{
          
          const response = await postData(`${Config.BASE_URL}/${Config.NEW_ADDRESS}`, formAddessData, "ADD_NEW_ADDRESS");
          if (response.status === true || response.code === 200) {
                setCustomerName(formAddessData.name);
                setCustomerAddress1(formAddessData.address_1)
                setCustomerAddress2(formAddessData.address_2)
                setCustomerCity(formAddessData.city)
                setCustomerState(formAddessData.state)
                setCustomerZip(formAddessData.zip)
                setCustomerPhone(phone)
                setStep(4);
                setIsVisible(false);
              

          }
          
         
       

      }catch(error){
          console.log(error)
          
      }
      }
};


const editAddress=async(value)=>{
    
    const edit = JSON.parse(value);
    setAddressFormData({
        pid:edit.pid,
        name:edit.name,
        email:edit.email,
        zip: edit.zip,
        city: edit.city,
        state: edit.state,
        address_1: edit.address_1,
        address_2: edit.address_2,
        phone: edit.phone,
        address_type: edit.address_type,

     });
     setNewAddress(true);
     sethasValidAddress(false)
    
  }
  const editAddressSubmit=async(e)=>{
    e.preventDefault(); // Prevent page reload
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Submit the form
     // console.log("Form data submitted:", formData);
      try{
        
        const response = await postData(`${Config.BASE_URL}/${Config.NEW_ADDRESS}`, formAddessData, "EDIT_ADDRESS");
        if (response.status === true || response.code === 200) {
        
        setCustomerName(formAddessData.name);
        setCustomerAddress1(formAddessData.address_1)
        setCustomerAddress2(formAddessData.address_2)
        setCustomerCity(formAddessData.city)
        setCustomerState(formAddessData.state)
        setCustomerZip(formAddessData.zip)
        setCustomerPhone(phone)
     
        setStep(4);
       
        }
       
    }catch(error){
        console.log(error)
        
    }
    }
  }
  const updatePhone=()=>{
    setStep(1);
  }
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
const  backAddress =()=>{
    setNewAddress(false);
    sethasValidAddress(true)
}
  const goToStep = (specificStep) => {
   
    if(specificStep===5 || specificStep===6){
        setStep(3);
        setNewAddress(true);
        sethasValidAddress(false)
    }else{
        setStep(specificStep);
    }
    

  };

  //Payment
  const [loading, setLoading] = useState(false);
  const handlePayment = async () => {
  
    setLoading(true);

    try {
       // const location = useLocation();
      // Generate the order ID and amount dynamically
      const orderId = `ORDER_${new Date().getTime()}`;
      const amount =cartamount; //1.00 // In paise (100.00 INR)cartamount
      const request ={
        "order_id": orderId,
        "amount": amount,
        "gateway": "phonepe",
        "seller_id":"1",
        "redirect_url":"https://webhook.site/e875700a-c73e-4819-a46b-e366f2c119b3",
        "X-BROWSER-FINGERPRINT":"8357426ac73fcd60b17355ab7de60421",
        "USER-AGENT":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36",
        "X-MERCHANT-DOMAIN":"https: //uat.nimbuspost.com"
    }
      // Call the backend to create an order and get the deepLink
      const response = await paymentPostData(`${Config.PAYMENT_BASE_URL}/${Config.PAYMENT_CREATE}`,request,'PAYMENT_CREATE');
      if (response.status === true || response.code === 200) {
        $('#cartModal').addClass('payment');

    //  console.log(response.data.payment_url)
      //const newWindow = window.open(response.payment_url, '_blank', 'width=500,height=600');

    //if (newWindow) newWindow.focus();
    const paymentWindow =window.location.href = response.data.payment_url;
//     const innerHTML = response.data.payment_url.innerHTML;

// console.log(innerHTML);
    //window.location.reload();
    //createOder();
    const timer = setInterval(() => {
      if (paymentWindow.closed) {
        clearInterval(timer);
        window.location.reload();
        // Fetch payment status once the popup is closed
        // checkPaymentStatus();
        // createOder();
      }
    }, 1000);
        //window.location.href = response.data.payment_url;
    }
   
    } catch (error) {
        //console.log(error)
  }
}
const checkPaymentStatus = async () => {
    // Simulate checking the status (you can fetch from your backend)
    const paymentResponse = {
      transactionId: 'TXN12345',
      status: 'SUCCESS',
    };
    console.log(paymentResponse)
   // setPaymentStatus(paymentResponse);
  };
const createOder=async()=>{
    const orderData = {
        payment_method: "PhonePe",
        payment_method_title: "Nimbuspost Checkout",
        set_paid: true,
        billing: {
          first_name: "Bulbul",
          country: "US",
          email: "john.doe@example.com",
          phone: "9650269497"
        },
        shipping: {
          first_name: "Bulbul",
          last_name: "Doe",
          address_1: "969 Market",
          address_2: "",
          city: "San Francisco",
          state: "CA",
          postcode: "110092",
          country: "US"
        },
        line_items: [
          {
            product_id: 47,
            quantity: 2
          }
        ],
        shipping_lines: [
          {
            method_id: "flat_rate",
            method_title: "Flat Rate",
            total: "10.00"
          }
        ]
      };
    
      const response = await orderPostData(storeKey,storeSKey, orderData,'PAYMENT_CREATE');
      console.log(response)
      
}


const calculateTotal = () => {
    if (!Array.isArray(cartitems)) return 0; // Fallback in case cartitems is not an array

    return cartitems.reduce((total, product) => {
      const price = parseFloat(product.product_sale_price || product.product_regular_price);
      return total + (price * product.product_quantity);
    }, 0);
  
  };



    return (
        <main id="content" role="main" className="checkout-page mt-5">
            <div className="container">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-body pt-0 pb-0">
                                <div className="row">
                                    <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                                        <div className='closecoupon' style={display1}>
                                            <div className="mt-3">
                                                <div className="bg-gray-1 rounded-lg">
                                                    <div className="mt-5 p-0 mb-2 checkout-table">
                                                        <div className="mb-2">
                                                            <h3 className="section-title mb-0 pb-2 font-size-25">Your order</h3>
                                                        </div>
                                                        <ul className="list-unstyled px-0 pt-0 product_list">
                                                        {cartitems && cartitems.length > 0 ? (
                                                              cartitems.map((product, index) => (<li className="border-bottom pb-1 mb-1" key={index}>
    
                                                                <div className="">
                                                                    
                                                                    <ul className="list-unstyled row mx-n2">
                                                                        <li className="px-2 col-auto">
                                                                                <img className="img-fluid" src={product.product_image} alt="Image Description"/>
                                                                                          
                                                                        </li>
                                                                        <li className="px-2 col text-warp">
                                                                            <h5 className="text-blue font-size-14 mb-0 font-weight-bold">Columbia</h5>
                                                                                <small>{product.product_title}</small>
                                                                                <div className="quantity">
                                                                                    {/* <input type="button" value="-" className="minus" onClick={handleDecrement} /> */}
                                                                                    <input
                                                                                        type="text"
                                                                                        step="1"
                                                                                        min="1"
                                                                                        name="quantity"
                                                                                        value={product.product_quantity}
                                                                                        title="Qty"
                                                                                        className="qty"
                                                                                        size="4"
                                                                                        readOnly
                                                                                    />
                                                                                    {/* <input type="button" value="+" className="plus" onClick={handleIncrement} /> */}
                                                                                </div>        
                                                                        </li>
                                                                        <li className="px-2 col pt-5 text-right ">
                                                                            <span className="font-size-14 fw-bold mr-2">₹ {product.product_price*product.product_quantity}.00</span>
                                                                        </li>                           
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                             ))
                                                            ) : (
                                                                <li className="border-bottom pb-1 mb-1">
                                                                <div className="">
                                                                    
                                                                    <ul className="list-unstyled row mx-n2">
                                                                        <li className="px-2 col-auto">
                                                                                            <img className="img-fluid" src="assets/images/img1.jpg" alt="Image Description" />
                                                                        </li>
                                                                        <li className="px-2 col text-warp">
                                                                            <h5 className="text-blue font-size-14 mb-0 font-weight-bold">Columbia</h5>
                                                                                <small>Headphones S50 Bluetooth</small>
                                                                                <div className="quantity">
                                                                                    {/* <input type="button" value="-" className="minus" onClick={handleDecrement} /> */}
                                                                                    <input
                                                                                        type="text"
                                                                                        step="1"
                                                                                        min="1"
                                                                                        name="quantity"
                                                                                        value={quantity}
                                                                                        title="Qty"
                                                                                        className="qty"
                                                                                        size="4"
                                                                                        readOnly
                                                                                    />
                                                                                    {/* <input type="button" value="+" className="plus" onClick={handleIncrement} /> */}
                                                                                </div>        
                                                                        </li>
                                                                        <li className="px-2 col pt-5 text-right ">
                                                                            <span className="font-size-14 fw-bold mr-2">₹ 1,100.00</span>
                                                                        </li>                           
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            )}
                                                        </ul>
                                                        <table className="table">
                                                                <tfoot>
                                                                    <tr>
                                                                        <th>Subtotal</th>
                                                                        <td className="text-right">₹ {calculateTotal().toFixed(2)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Shipping</th>
                                                                        <td className="text-right">₹ 00.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Total</th>
                                                                        <td className="text-right"><strong>₹ {calculateTotal().toFixed(2)}</strong></td>
                                                                    </tr>
                                                                </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <div className="text-right powered_by">
                                            Powered By <img src="assets/images/nimbus_logo.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 order-lg-1" id="msform">
                                        <div className="progressbar_head">
                                            <img src="assets/images/logo.jpg" className="logo_height" />
                                                <div className="progressbar">
                                                    <div className="progress px-1" style={{height: '3px'}}>
                                                        
                                                        <div className="progress-bar" role="progressbar" style={progress} aria-valuenow={currentStep} aria-valuemin="0" aria-valuemax="3"></div>
                                                    </div>
                                                    <div className="step-container d-flex justify-content-between">
                                                        <div className={`step-circle ${currentStep >= 1 ? 'active' : ''}`}> <span className="bs-stepper-label"><i
                                                                        className="fa-solid fa-check"></i><span className={`${currentStep >= 1 ? 'action' : ''}`}> 1</span></span> Mobile</div>
                                                        <div className={`step-circle ${currentStep >= 2 ? 'active' : ''}`}><span className={`${currentStep >= 2 ? 'action' : ''}`}>2</span>Address</div>
                                                        <div className={`step-circle ${currentStep >= 3 ? 'active' : ''}`}><span className={`${currentStep >= 3 ? 'action' : ''}`}>3</span> Payment</div>
                                                    </div>

                                                </div>
                                            
                                        </div>

                                        <div id="multi-step-form">
                                            <div className="step step-1" style={{display:display}}>
                                                    {step === 1  && (
                                                        <div className="col-md-8 ml-auto mr-auto">
                                                            <div className="newsletter-steps step1 mb-4 ">
                                                                <h5 className="fs-subtitle mt-5 mb-5">Enter Your Phone Number</h5>
                                                                <form onSubmit={mobileNumberSubmit}>
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${errors.phone ? 'u-has-error' : ''}`}
                                                                    name="phone"
                                                                    placeholder="Enter phone number"
                                                                    value={formData.phone}
                                                                    onChange={handleInputChange}
                                                                    minLength={10}
                                                                    maxLength={10}
                                                                />
                                                                {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber}</p>}

                
                                                                    <button type="submit" className="action-button mt-5 next-btn">Send OTP</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )} 
                                                    {step === 2  && (
                                                        <div className="newsletter-steps step2 mb-4" style={{display:'block'}}>
                                                            <h5 className="fs-subtitle mt-5">Please Enter the one time password</h5>
                                                            <p>Sent to +91  ******* {getLastFourDigits(mobile)} <small onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></small></p>
                                                            <form onSubmit={optVerify}>
                                                                <OTPInput length={6} onChange={handleOtpChange} />
                                                                {error.message}
                                                                {error && <p className="text-danger">{error}</p>}
                                                                <button type="submit" className="btn action-button btn-primary next-step" >Verify OTP</button>
                                                            </form>
                                                        </div>
                                                    )}

                                            </div>
                                            {step === 3  && (
                                             
                                            <div className="step step-2" >

                                                {hasValidAddress && (<div className="selectadd">
                                                    <div className="text-left d-flex mt-4 mb-3">
                                                        <div className="select_address">
                                                            <h5 className="section-title mb-0 pb-1 font-size-20" style={{border: 'none', background: 'transparent'}}>Select Address</h5>
                                                                <p className="mb-0 ml-auto"><i className="fa-regular fa-mobile"></i> ******* {getLastFourDigits(mobile)} <small onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></small></p>
                                                        </div>
                                                        <div className="add_address mb-0 ml-auto pt-3">
                                                            <small  onClick={addNewAddess} className="addaddress fw-bold text-dark" rel="addnewaddress"><i className="fa-regular fa-plus pl-2"></i> Add New Address</small>

                                                        </div>
                                                    </div>

                                                                
                                                    <div className="grid select_address_scroll">
                                                    {addressList && addressList.length > 0 ? (
                                                                     addressList.map((item) => (

                                                                        //{(item.name !== '' && item.name !== null && item.email !== '' && item.email !== null) ? (
                                                       <label className="card pt-3 pb-3 text-left mb-2 pl-6 gray_bg" key={item.pii_id}>
                                                            {(item.name !== '' && item.name !== null && item.email !== '' && item.email !== null) ? (
                                                                                <>
                                                                <input
                                                                                        name="plan"
                                                                                        className="radio"
                                                                                        type="radio"
                                                                                        value={JSON.stringify(item)}
                                                                                        onClick={selectAddress}
                                                                                    />
                                                            <p className="mb-0"><strong>{item.name}</strong><br/> {item.address_1}, {item.address_2}, {item.city}, {item.state}, {item.zip} </p>
                                                                <ul className="action_btn">
                                                                
                                                                
                                                                    <li><small className="addaddress" rel="addnewaddress" onClick={() => editAddress(JSON.stringify(item))}><i className="fa-solid fa-pen-to-square"></i></small></li>
                                                                            
                                                                </ul>
                                                                </>
                                                                            ) : null}
                                                        </label>
                                                
                                                         ))
                                                        ) : (
                                                            <label></label>
                                                        )}
                                                                    
                                                    </div>
                                                   
                                                    {/* <button type="button" className="action-button btn btn-primary next-step">Save and Continue</button>             */}
                                                </div>)}
                                                {isNewAddress && (
                              
                                                    //  <form >
                                                    <div className="addnewaddress mt-4 " >
                                                    <div className="text-left d-flex">
                                                        <div className="d-block">
                                                            <h5 className="section-title mb-0 pb-1 font-size-20" style={{border: 'none', background: 'transparent'}}>Add New Address</h5>
                                                                <div className="text-left mt-2">
                                                                            <small className="addaddress" onClick={backAddress} rel="selectadd"><i className="fa-solid fa-square-arrow-left"></i> Back</small>
                                                                </div>
                                                        </div> 
                                                        <p className="mb-0 ml-auto pt-3"><i className="fa-regular fa-mobile"></i> ******* {getLastFourDigits(mobile)} <small onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></small></p>
                                                    </div>
            
                                                    <div className="border p-3 mt-2 pt-0 pb-0" >
                                                        <div className="row mt-3">
                                                            <div className="col-md-6">
                                                                <div className="js-form-message mb-3">
                                                                  
                                                                    <input type="text" className="form-control" name="name" placeholder="Full Name" aria-label="Jack" required  value={formAddessData.name}
          onChange={handleAddressChange} />
           {errors.name && <small className="text-danger">{errors.name}</small>}
                                                                </div>  
                                                            </div> 
                                                            <div className="col-md-6"> 
                                                                <div className="js-form-message mb-3">
                                                                <input type="email" className="form-control" name="email" placeholder="Email." aria-label="Email" required value={formAddessData.email}
          onChange={handleAddressChange} />
            {errors.email && <small className="text-danger">{errors.email}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="js-form-message mb-3">
                                                                <input type="text" className="form-control" name="zip" placeholder="Pincode" aria-label="Pincode" required  value={formAddessData.zip}
          onChange={handleAddressChange} />
            {errors.zip && <small className="text-danger">{errors.zip}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="js-form-message mb-3">
                                                                <input type="text" className="form-control" name="city" placeholder="City" aria-label="City" required   value={formAddessData.city}
          onChange={handleAddressChange}/>
            {errors.city && <small className="text-danger">{errors.city}</small>}
                                                                </div> 
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="js-form-message mb-3"> 
                                                                <input type="text" className="form-control" name="state" placeholder="State" aria-label="State" required  value={formAddessData.state}
          onChange={handleAddressChange}/>
            {errors.state && <small className="text-danger">{errors.state}</small>}
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-12">
                                                                <div className="js-form-message mb-3"> 
                                                                <input type="text" className="form-control" name="address_1" placeholder="Address 1" aria-label="Address 1" required   value={formAddessData.address_1}
          onChange={handleAddressChange}/>
            {errors.address_1 && <small className="text-danger">{errors.address_1}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="js-form-message mb-3"> 
                                                                <input type="text" className="form-control" name="address_2" placeholder="Address 2" aria-label="Address 2"  value={formAddessData.address_2}
          onChange={handleAddressChange}/>
              <input type="hidden" className="form-control" name="phone" placeholder="phone" aria-label="Address 2"  value={formAddessData.phone?formAddessData.phone:mobile}
          onChange={handleAddressChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="js-form-message mb-3"> 
                                                                    <p className="form-label text-left mb-2"> Save address as :<span className="text-danger">*</span></p>
                                                                        <div className="d-flex">
                                                                            <div className="custom-control custom-radio mr-5">
                                                                            <input type="radio" className="custom-control-input" id="home" name="address_type"   value="home"  checked={formAddessData.address_type === 'home'}
              onChange={handleAddressChange}/>
                                                                                    <label className="custom-control-label form-label"> Home</label>
                                                                            </div>
                                                                            <div className="custom-control custom-radio mr-5">
                                                                            <input type="radio" className="custom-control-input" id="work" name="address_type" value="work"
              checked={formAddessData.address_type === 'work'} onChange={handleAddressChange}/>
                                                                                    <label className="custom-control-label form-label" > Work</label>
                                                                            </div>
                                                                            <div className="custom-control custom-radio"> 
                                                                            <input type="radio" className="custom-control-input" id="other" name="address_type"  value="other"
              checked={formAddessData.address_type === 'other'} onChange={handleAddressChange}/>
                                                                                    <label className="custom-control-label form-label" > Other </label>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {formAddessData.pid && (
                                                            <button
                                                            type="submit"
                                                            className="action-button btn btn-primary next-step"
                                                            onClick={editAddressSubmit}
                                                            >
                                                            Edit and Continue
                                                            </button>
                                                        )
                                                        }
                                                        {!formAddessData.pid && (
                                                            <button
                                                            type="submit"
                                                            className="action-button btn btn-primary next-step"
                                                            onClick={handleAddressSubmit}
                                                            >
                                                            Save and Continue
                                                            </button>
                                                         )
                                                        }
                                                             
                                                </div>
                                                // </form>
                                                )}
                                            </div>
                                            )} {step === 4  && (
                                            <div className="step step-3">
                                                    <div className="mb-4 text-left d-flex">
                                                        <h5 className="section-title mb-0 pb-1 font-size-20" style={{border: 'none', background: 'transparent'}}> Select Payment Mode</h5>
                                                            <p className="mb-0 ml-auto"><i className="fa-regular fa-mobile"></i> ******* {getLastFourDigits(mobile)} <small onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></small></p>
                                                    </div>
                                                    <div className="card p-3 text-left mb-5 gray_bg"> 
                                                        <h6><strong>Delivering To:</strong></h6>
                                                        <p className="mb-0"><strong>{customername}</strong><br />{customeraddress1}, {customeraddress2}, {customercity}, {customerstate}, {customerzip}</p>
                                                                <div className="User-area">
                                                                <div className="User-avtar" onClick={toggleDropdown}><i className="fa-solid fa-ellipsis"></i></div>
                                                            {isOpen && (
                                                            <ul className="User-Dropdown U-open" >
                                                                <li><small  className="" onClick={() => goToStep(3)} disabled={step === 1 && step === 2}>Change Address</small></li>
                                                                <li><small className="" onClick={() => goToStep(5)}>Edit Address</small></li>
                                                                <li><small className="" onClick={() => goToStep(6)}>Add Address</small></li>
                                                            </ul>
                                                            )}
                                                                    
                                                                    </div>
                                                                </div>
                                                    
                                                    <div className="row payments">
                                                        <div className="col-md-6">
                                                             <button onClick={handlePayment} disabled={loading} style={{border:'none', background:'none'}}><img src="../assets/images/phonepe.png" alt="" style={{width:'100%'}}/></button>
                                                        </div>
                                                        {/* <div className="col-md-6"> 
                                                            <a href="#"><img src="assets/images/p2.png" /> Credit / Dedit card</a>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <a href="#"><img src="assets/images/p3.png" /> Net Banking</a>
                                                        </div> 
                                                        <div className="col-md-6">
                                                            <a href="#"><img src="assets/images/p4.png" /> Cash On delivery</a>
                                                        </div>  */}
                                                    </div>
                                            </div>
                                            )}
                                               <div className="footer_content">
                                            <ul>
                                            

                                              {links.shipping_policy && <li><a href={links.shipping_policy} target="_blank" rel="noopener noreferrer">Shipping Policy</a></li>}
                                              {links.refund_policy  && <li> <a href={links.refund_policy} target="_blank" rel="noopener noreferrer">Refund Policy</a></li> }
                                              {links.privacy_policy && <li><a href={links.privacy_policy} target="_blank" rel="noopener noreferrer">Privacy policy</a></li>}
                                                {/* <li><a href="#" target="_blank">Shipping Policy</a></li>
                                                <li><a href="#" target="_blank">Refund Policy</a></li>
                                                <li><a href="#" target="_blank">Privacy Policy</a></li> */}
                                            </ul>

                                        </div>

                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </main>

    );
}

export default  Checkout;