import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';


import { paymentstatus } from '../Services/Payment';




function Thankyou(){

    const location = useLocation();
    const { order } = location.state || {};
    const { seller } = location.state || {};  
    const { links } = location.state || {};
    const [paymentid, setPaymentId] = useState('');
    const [paymentstatuss, SetPaymentStatus] = useState('');
  
    const navigate = useNavigate();
    useEffect(() => {
     //  console.log(links)
       
        const paymentstate=async()=>{
            const endpoint = 'https://payment.bp.nimbuspost.com/api/v1/payment/status';
            const playload={
                "gateway":"phonepe",
                "order_id":order,//"423534345654654",//order,
                "seller_id":seller,// "1",//seller,
             
              };
              
              try{
                const response = await paymentstatus(endpoint, playload, "PAYMENT STATUS");
                if (response.status === true || response.code === 200) {
                    const paymentid = response.data.payment_id;
                    setPaymentId(paymentid)
                    const paymentstatus = response.data.payment_status;
                    SetPaymentStatus(paymentstatus);
                }
              }catch(error){
                console.log(error);
              }
               
              
           
            };
          

            paymentstate();
        


    },[location.search]);
    return (
        <main id="content" role="main" className="checkout-page mt-5">
        <div className="container">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body pt-0 pb-0">
                            <div className="row">
                                <div className="" id="msform">
                                    <div className="mt-3">
                                        <img src="assets/images/logo.jpg" className="logo_height"/>
                                        <div className=" congratulations_con mt-2">
                                            <div className="center-align">
                                                <img src="assets/images/cong.png" width="80px" />
                                                <h2>Congratulations</h2>
                                                <p className="payment">Payment Id: {paymentid}</p>
                                                {/* <p>{order?order:'423534345654654'}</p> */}
                                                {/* <p>{seller}</p> */}
                                                {paymentstatuss && (<p className="payment">Payment Status : {paymentstatuss}</p>)}
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="action-button btn btn-primary next-step"
                                                    onClick={() => {window.parent.postMessage("continue_shipping", "*")}}
                                                >
                                                    Continue Shipping
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer_con">
                                                    <div className="powered_by">
                                                        Powered By <img src="assets/images/nimbus_logo.jpg" />
                                                    </div>
                                                    <div className="text-right footer_content">
                                                        <ul>
                                                        {links.shipping_policy && <li><a href={links.shipping_policy?links.shipping_policy:''} target="_blank" rel="noopener noreferrer">Shipping Policy</a></li>}
                                                        {links.refund_policy  && <li> <a href={links.refund_policy} target="_blank" rel="noopener noreferrer">Refund Policy</a></li> }
                                                        {links.privacy_policy && <li><a href={links.privacy_policy} target="_blank" rel="noopener noreferrer">Privacy policy</a></li>}
                                                           
                                                        </ul>

                                                    </div>
                                                </div>
                            
        </div>
        </div>
        </div>
        </div>
        </main>
    );
}
export default Thankyou;