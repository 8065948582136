import axios from "axios";
import Config from "../Config/Config";
import {logsPostData} from './Logs'
let PAYMENT_TOKEN = '';
let tokenExpiryTime = null; 

const generateToken = async () => {
    try{
        const end=`${Config.PAYMENT_RAZOR}/${Config.PAYMENT_TOKEN}`;
        //console.log(end);
      const response = await fetch(end, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:JSON.stringify({ username: 'vip@gmail.com', password: 'nmbcshfv'}),
      });
//  console.log(response.data);
   
      const data = await response.json();
      //console.log(data.data.data)
     
        PAYMENT_TOKEN = data.data.data;
        
        return data;
    }catch(error){
        console.log(error)
    }
}
const getValidToken = async () => {
    if (!PAYMENT_TOKEN) {
      await generateToken(); // Generate a new token if it's missing or expired
   }
    return PAYMENT_TOKEN;
  };
  
const createGatewayApi = async () => {
    const validToken = await getValidToken();
   // console.log(validToken);
    return axios.create({
      baseURL: Config.PAYMENT_RAZOR,
      headers: {
        'Content-Type': 'application/json',
       // Authorization: `Bearer ${Config.SETTING_TOKEN}`,
       Authorization: `Bearer ${validToken}`,
      },
    });
};
export const paymentRozarpay = async (endpoint, data, message)=>{
    try {
        const apiInstance = await createGatewayApi();
        //console.log(endpoint);
        const response = await apiInstance.post(endpoint, data);
       //console.log(response);
        if (response.data.status === true || response.data.code === 200) {
         // eventlogs(localStorage.getItem('store_id'),message, "Select payment gateway");
        return response.data; // Return the response data
        }
      } catch (error) {
        console.error('Error posting data:', error?.response?.data || error.message);
        throw error;
      }
}
// export const paymentRstatus=async(endpoint, data, message)=>{
//   try {
//     const apiInstance = await createGatewayApi();
   
//     const response = await apiInstance.get(endpoint, data);
    
//     return response.data; // Return the response data
    
//   } catch (error) {
//     console.error('Error posting data:', error?.response?.data || error.message);
//     throw error;
//   }
// }
export const paymentRstatus = async (endpoint, params, message) => {
  try {
    // Create the API instance (assuming it's an Axios instance)
    const apiInstance = await createGatewayApi();

    // Use the params object to send query parameters in the GET request
    const response = await apiInstance.get(endpoint, { params });
    //console.log(response);
    // Return the response data
    return response.data;

  } catch (error) {
    // Log the error and rethrow it for higher-level handling
    console.error('Error fetching payment status:', error?.response?.data || error.message);
    throw error;
  }
};




