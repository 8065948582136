import React, { useState, useEffect } from "react";
import { json, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { postData, vpostData, getData, orderPostData,  } from '../Services/Services';
import {settingGetData, checkoutpostData, shipmethod, settingPostData, getUserChannel } from '../Services/Seting';
import { getpaymentmode,paymentPostData, paymentstatus } from '../Services/Payment';
import {paymentRozarpay} from '../Services/Rozargetwat';
import {createWooCommerceOrder } from '../Services/Orders';
import Config from "../Config/Config";
import OTPInput from "./OTPInput";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

function Checkout2(){
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [activetab, setActiveTab] =useState(false);
    const [crossedset, setCrossedSet] = useState(false);
    const [crossed4set, set4CrossedSet] = useState(false);
    const location = useLocation(); // Provides access to the current URL
    const queryParams = new URLSearchParams(location.search);
    const getorder=queryParams.get('order');
    const [storeKey, setStoreKey] = useState('');
    const [storeSKey, setStoreSKey] = useState('');
    const [products, setProducts] = useState('');
    const [cartamount, setCartAmount] = useState('');
    const [storeId, setStoreid] =useState('');
    const [merchantId, setMerchantid] =useState('');
    const [storeConfigData, setStoreConfig] =useState('');
    const [cartitems, setCartitems] = useState('');
    const [cartialltems, setCartALLitems] = useState('');
    const [links, setLink] =useState('');
    const [orderslineitems, setOrdersLineItems] =useState('');
    const [standardcharge, setStandardCharge] = useState('');
    const [expresscharge, setExpressCharge] = useState('');
    const [fingerprint, setFingerprint] = useState('');
    const [prepaiddiscount, setPrepaidDiscount] = useState('');
    const [afterdicountprice, setAfterDiscountprice] = useState('');
    const [coddiscount, setCodDiscount] = useState('');
    const [aftercodediscountprice, setAfterCodDiscountprice] =useState('');
    const [mid, setMid]= useState('');
    const[sid, setSid] = useState('');
    const navigate = useNavigate();
    const [companylogo, setCompanyLogo] = useState('');
    const [settingpaymod, setSettingPaymode] = useState('');
    const [siteurl, setSiteUrl] = useState('');

    const [sitekey, setSiteKey] = useState('');
    const [sitesec, setSiteSec] = useState('');

    const [visibleModes, setVisibleModes] = useState({}); // Object to manage visibility per mode
    useEffect(() => {
        if(step===1){
            setActiveTab(true)
        }else if(step===2){
            setActiveTab(true)
        }else if(step===3){
            setActiveTab(true)
            setCrossedSet(true)
        }else if(step===4){
            setActiveTab(true)
            setCrossedSet(true)
            set4CrossedSet(true)
           
        }else{
            setActiveTab(false)
        }
        if (getorder && getorder.trim() !== '') {
            const orderdetails= atob(getorder);
          
            const storedata=JSON.parse(orderdetails); 
         //  console.log(storedata);
           
          
            const items = storedata.products;

            setMid(storedata.merchant_id);
            setSid(storedata.store_id)
            let orderlineitems = [];
            items.forEach(( items,index) => {
                let lineitems = {
                    product_id : items.product_id,
                    quantity : items.product_quantity
                };
                orderlineitems = [lineitems]
               // setOrdersLineItems(lineitems);
                
            });
            setOrdersLineItems(orderlineitems);
         
    
            setProducts(storedata.products);
            setCartAmount(storedata.total_amount);
            const datacart=JSON.stringify(storedata.products)
            setCartitems(storedata.products);
            setCartALLitems(orderdetails);
           // localStorage.setItem('cartitems', datacart);
            
        
    
            const sArraydata= storedata.store_info;
           
            let storeSecrectKey ='';
            sArraydata.forEach(( sArraydata,index) => {
                storeSecrectKey =sArraydata.consumer_secret;
                setStoreKey(sArraydata.consumer_key);
                setStoreSKey(sArraydata.consumer_secret);
               
                
            });
            setStoreSKey(storeSecrectKey);
           
            // const walletcheck = async()=>{
               
               
            //     try{
            //         const response = await walletBalance(storedata.merchant_id, 'check Wallet Ballance');
                  
            //         if (typeof response !== 'number') {
            //             throw new Error("Invalid response: Balance is not a number");
            //           }
                    
                  
            //     }catch(error){
            //         console.log(error)
            //     }
            // }
          
            const configSetup =async()=>{
                    const merchant_id='merchant_id='+storedata.merchant_id;
                    const storeid = '&store_id='+storedata.store_id;
                    const url = `${Config.SETTING_BASE_URL}/${Config.SETTING_CONFIG}`+merchant_id+storeid;

                 
                   
                        try{
                            const response = await settingGetData(url, "MObile_send_otp");
                          //  console.log(response);

                            
                            
        
                            if (response.status === true || response.code === 200) {
                                const storeDatas=response.data.rows;
                                var payment = '';
                                var storeSettinglink = '';
                                storeDatas.forEach(( storeData, index) => {
                                 
                                    setStoreid(storeData.store_id)
                                    setMerchantid(storeData.merchant_id)
                                    localStorage.setItem('store_id',storeData.store_id);
                                    localStorage.setItem('merchant_id',storeData.merchant_id);
                                    storeSettinglink = storeData.configuration;
                                    setStoreConfig(storeSettinglink)
                                    payment =storeData.configuration
                                     
                                });
                                const settings = JSON.parse(storeSettinglink);
                                setCompanyLogo(settings.general_settings.upload_logo);
                                setLink(settings.general_settings.links);
                                const modeofpayment = JSON.parse(payment);
                                setSettingPaymode(modeofpayment.payment_settings.payment_modes);
                                localStorage.setItem('paymentMode',JSON.stringify(modeofpayment.payment_settings));

                                //console.log(modeofpayment.payment_settings.payment_modes);
                                
                               
                            }
                            
                        }catch(error){
                            //setMessage(error.message + ' Failed Please try again.');
                        }
                                
            };

        const methodofshiiping=async()=>{
            const methodplayload={
                "merchant_id":merchantId,
                "store_id": storeId,
                "order_amount": storedata.total_amount,
              };
              
              try{
                const shippingresponse = await shipmethod(`${Config.SETTING_BASE_URL}/${Config.SHIPPING_METHOD}`, methodplayload, "SHIPPING_METHOD");
                if (shippingresponse.status === true || shippingresponse.code === 200) {
                    const shipmentcharge = shippingresponse.data.shipping_charges;
                    setStandardCharge(shipmentcharge.standard);
                    setExpressCharge(shipmentcharge.express);
                    setPrepaidDiscount(shippingresponse.data.prepaid_discount.discount_amount);
                    setAfterDiscountprice(shippingresponse.data.prepaid_discount.final_price);
                    setCodDiscount(shippingresponse.data.cod_charges.discount_amount);
                    setAfterCodDiscountprice(shippingresponse.data.cod_charges.final_price)
                 
                }

                }catch(error){
                    //setMessage(error.message + ' Failed Please try again.');
                }
              
           
            };

        //https://quick-checkout.nimbuspost.com/api/store/get-channels?merchant_id=1&store_id=1&page=1&limit=10

        const storeinfo = async()=>{
            try{
                const merchant_id='?merchant_id='+storedata.merchant_id;
                const storeid = '&store_id='+storedata.store_id+'&page=1&limit=10';
                const url = `${Config.USER_CHANNEL}/${Config.GET_CHANNEL}`+merchant_id+storeid;
                const response = await getUserChannel(url, "User CHannel GET");
                if (response.status === true || response.code === 200) {
                    //console.log(response.data[0]);
                    setSiteUrl(response.data[0].apiKey);
                    setSiteKey(response.data[0].password);
                    setSiteSec(response.data[0].sharedSecret)

                    
                }
               // console.log(response);

            }catch(error){
                console.log(error);
            }
        }
        const paymentmod=async()=>{
            const pmid = '?seller_id='+storedata.merchant_id;
            const gateway = '&gateway_name=phonepe';
            const url = `${Config.PAYMENT_BASE_URL}/${Config.PAYMENT_MODE}`+pmid+gateway;
                try{
                    const res = await getpaymentmode(url, 'GETWAY_METHOD_FETCH');

                   // console.log(res)
                    if (res.status === true || res.code === 200) {
                        const pmode=res.data.payment_mode;
                        const paymentArray = pmode.split(",");
                        setPaymode(paymentArray);
                       // console.log(paymentArray);
                    }
                   
                }catch(error){
                    console.log(error);
                }
        }
           // walletcheck();
            configSetup();
            storeinfo();
            methodofshiiping();
           // paymentmod();
        }
        const loadFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setFingerprint(result.visitorId);
          };
      
          loadFingerprint();

    },[location.search]);
 

    //console.log(orderslineitems)
    const [isValid, setIsValid] = useState(false);
    const [mobile, setMobile] = useState('');
    const [phone , setPhone]  =useState('');
// State for form validation messages
    const [errors, setErrors] = useState({});
    const [error, SetError] = useState('');
    const calculateTotal = () => {
        if (!Array.isArray(cartitems)) return 0; // Fallback in case cartitems is not an array
    
        return cartitems.reduce((total, product) => {
          const price = parseFloat(product.product_sale_price || product.product_regular_price);

          return total + (price * product.product_quantity);
        }, 0);
      
      };
    
    const [formData, setFormData] = useState({
        phone: `${localStorage.getItem('phone')}`,
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value }); // Update the specific field
        setIsValid(true)
    };
    
    // Validate form data
    const validateForm = () => {
        let validationErrors = {};
    
        // Simple phone number validation (check if it's empty or invalid)
        const phoneRegex = /^[6-9]\d{9}$/;
        if (!formData.phone) {
            validationErrors.phone = "Phone number is required.";
        } else if (!phoneRegex.test(formData.phone)) {
            validationErrors.phone = "Please enter a valid 10-digit phone number.";
        }
        return validationErrors;
    };
    
    const mobileNumberSubmit= async (e) => {
        e.preventDefault();
       
        const validationErrors = validateForm();
            if (Object.keys(validationErrors).length === 0) {
                setMobile(formData.phone);
                setPhone(formData.phone)
             
                // Form is valid, submit the form
               // console.log('Form data submitted:', formData);
                const payload ={
                    "mobile_no": formData.phone,
                    "services": [
                        "sms"
                    ],
                    "type":"",
                    "event_type": "otp"
                 }
                 //setStep(2);
                try{
                    const response = await postData(`${Config.BASE_URL}/${Config.SEND_OTP}`, payload, "MOBILE_NUMBER_ENETER");
                    if (response.status === true || response.code === 200) {
                        localStorage.setItem('pii', response.data.pii_id);
                        setActiveTab(true)
                        setStep(2);

                    }else{
                        SetError('Something Runtime Error!');
                    }
                }catch(error){
                    SetError(error.message);
                }
              
            } else {
                // Form has validation errors
                setErrors(validationErrors);
            }
    }
    const getLastFourDigits = (mobile) => {
        // Check if the input has enough digits and extract the last 4 digits
        return mobile.length >= 4 ? mobile.slice(-4) : mobile;
      }
    const [otp, setOtp] = useState(''); // State to store OTP input
    const [request, setRequest]= useState('');

    const handleOtpChange = (value) => {
      setOtp(value);
    };
    const optVerify = async (event) => {
      event.preventDefault();
      
      if (otp.length !== 6) {
          SetError("OTP must be 6 digits.");
          return;
        }
       
        try {
          const payload={
              'pii_id': localStorage.getItem('pii'),
              'otp': parseInt(otp)
          };
          const response = await vpostData(`${Config.VARIFYOTP_BASE_URL}/${Config.VARIFYOTP}`, payload, "MOBILE_NUMBER_OTP VERIFY");
         
          if (response.status === 200) {
              localStorage.setItem('phone', mobile);
              localStorage.setItem('pii', response.pii_id);
               const filter='phone';
               const endurl= `${Config.BASE_URL}/${Config.GET_ADDRESS}?${filter}=${mobile}`;
              
              const getAddress=await getData(endurl, 'Featch Address');
               checkUserAddress(getAddress.data);
              //console.log(getAddress.data);
              if (getAddress.status === true || getAddress.code === 200) {
                  if(getAddress.data.length > 0){
                  localStorage.setItem('preaddress',  JSON.stringify(getAddress.data));
                 const setRequest={
                          "storeid": storeId,
                          "merchant_id": merchantId,
                          //"channel_id":2,
                          "status":"pending",
                          "checkout_configrations":{
                              "item_details":cartialltems,
                              "user_type":"old user"
                          },
                      };
                      const checkout = await checkoutpostData(`${Config.SETTING_BASE_URL}/${Config.SETTING_CHECKOUT}`, setRequest, "CHECKOUT");
                  }else{
                       setRequest={
                          "storeid": storeId,
                          "merchant_id": merchantId,
                          //"channel_id":2,
                          "status":"pending",
                          "checkout_configrations":{
                              "item_details":cartialltems,
                              "user_type":"new user"
                          },
                          
                      };
                      const checkout = await checkoutpostData(`${Config.SETTING_BASE_URL}/${Config.SETTING_CHECKOUT}`, setRequest, "CHECKOUT");
                  }
                 
  
                 //setDisplay('none');
               setStep(3);
               setCrossedSet(true)
          
              }else{
                  SetError('Something Runtime Error!');
              }
  
        
          }else{
              SetError('Something Runtime Error!');
          }
       
        
        
     
        } catch (error) {
          //throw error;
          SetError('Invalid OTP');
         // console.log(error.message);
        }
      
    };
const checkUserAddress=async(address)=>{
    let zip =address[0].zip;
    if(zip==='' || zip===null){
        setNewAddress(true);
        sethasValidAddress(false)
    }else{
        setNewAddress(false);
        sethasValidAddress(true)
    }



}
    //Address
const addressList = JSON.parse(localStorage.getItem('preaddress'));
const [customername, setCustomerName ]= useState('');
const [customeremail,setCustomerEmail] = useState('')
const [customeraddress1, setCustomerAddress1 ]= useState('');
const [customeraddress2, setCustomerAddress2 ]= useState('');
const [customercity, setCustomerCity ]= useState('');
const [customerstate, setCustomerState ]= useState('');
const [customerzip, setCustomerZip ]= useState('');
const [customerphone, setCustomerPhone]= useState('');


const [formAddressData, setFormAddressData] = useState({
    phone: `${localStorage.getItem('phone')}`,
});
const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setFormAddressData({ ...formData, [name]: value }); // Update the specific field
   // setIsValid(true)
};

const selectAddress = async(event)=> {
    const seladdress = JSON.parse(event.target.value);
   
     setFormData({
        pid:seladdress.pid,
        name:seladdress.name,
        email:seladdress.email,
        zip: seladdress.zip,
        city: seladdress.city,
        state: seladdress.state,
        address_1: seladdress.address_1,
        address_2: seladdress.address_2,
        phone: seladdress.phone,
        address_type: seladdress.address_type,

     })
     setCustomerName(seladdress.name);
     setCustomerAddress1(seladdress.address_1)
     setCustomerAddress2(seladdress.address_2)
     setCustomerCity(seladdress.city)
     setCustomerState(seladdress.state)
     setCustomerZip(seladdress.zip)
     setCustomerPhone(mobile)

     setStep(4);
     set4CrossedSet(true)
  
  }

  const [isNewAddress, setNewAddress] = useState(false);
  const [hasValidAddress, sethasValidAddress] =useState(true)
  const [formAddessData, setAddressFormData] = useState({
    name: customername ? customername : '',
    phone:`${localStorage.getItem('phone')}`, 
    email: '',
    zip: '',
    city: '',
    state: '',
    address_1: '',
    address_2: '',
   // phone: customer_phone,
    address_type: 'home',
  });
  const [selectedOptions, setSelectedOptions] = useState({
    address: '',
    plan: ''
  });
  const [destionzip, setDestionZip] = useState('');
  const [selectAdd, setSelectAdd] = useState('');
  const [serviceable, setServiceable] = useState('');
  const [eodstandard, setEodStandard] = useState('');
  const [eodexpress, setEodExpress] = useState(''); 
  const[shipppingcgarge, setShippingCharge] = useState('');
  const [discount, setDiscount] = useState('');
  const [finalamount, setFinalAmount] = useState('')
    // Handle change for each radio group
const handleOptionChange = (event) => {
        const { name, value } = event.target;
        setSelectedOptions((prevState) => ({
          ...prevState,
          [name]: value
        }));
      
        if(event.target.name==='address'){
        
      
        const pid = event.target.value;
        const filter='pid';
   
      
        selectedCustomerAddress(filter, pid);
        } 
        // console.log(event.target.value)
        if(event.target.name==='plan'){
        var charge = '';
        if(event.target.value==='standard'){
            setShippingCharge(standardcharge);
            charge = standardcharge;
        }else if(event.target.value==='express'){
            setShippingCharge(expresscharge);
            charge = expresscharge;
        }
       
        const dis=parseFloat(prepaiddiscount?prepaiddiscount:0).toFixed(2);
        const sh =  parseFloat(charge?charge:0).toFixed(2);
        const shch=(sh-dis).toFixed(2);
        const pp = calculateTotal().toFixed(2);

        const productwithshiping=(parseFloat(pp?pp:0)+parseFloat(sh?sh:0));
      

        setDiscount(dis);
   
        const totalamount =(productwithshiping-dis).toFixed(2);
        setFinalAmount(totalamount);

    }
       
       
      };



const selectedCustomerAddress = async(filter, pid)=>{

    const endurl= `${Config.BASE_URL}/${Config.GET_ADDRESS}?${filter}=${pid}`;

    const getAddress=await getData(endurl, 'Featch Address');
    //console.log(getAddress);
    let zipcode = '';
    if (getAddress.status === true || getAddress.code === 200) {
        setDestionZip(getAddress.data[0].zip);
        zipcode=getAddress.data[0].zip;
        setSelectAdd(getAddress.data[0]);
    }else{
        setErrors('Not Serviceable');
    }
    const serviceCheckPlayload ={
        "store_id": storeId,
        "source_pincode": 201301,
        "destination_pincode": zipcode
    };

    const servicecheck = await settingPostData(`${Config.SETTING_BASE_URL}/${Config.SERVICEABILITY}`, serviceCheckPlayload, 'Serviceability Check');
    if (servicecheck.status === true || servicecheck.code === 200) {
        setServiceable(servicecheck.data.serviceable);
        setEodExpress(servicecheck.data.delivery_option.rush.calculatedEDDDates);
        setEodStandard(servicecheck.data.delivery_option.standard.calculatedEDDDates);
        //setPrepaidDiscount()

    }else{
        setErrors('Not Serviceable');
    }
   

    
   
  
   //setFinalAmount(sch);
   // alert(standardcharge)
    //shippingchrage();
   
    
}
const validateCForm = () => {
    const errors = {};
    if (!selectedOptions.address) {
        errors.address = "Please select an address.";
    }
    if (!selectedOptions.plan) {
        errors.plan = "Please select a shipping method.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
};

const customerAddress = async(event)=>{
    event.preventDefault();
    if (validateCForm()) {
      
   

    setCustomerName(selectAdd.name);
    setCustomerEmail(selectAdd.email);
    setCustomerAddress1(selectAdd.address_1)
    setCustomerAddress2(selectAdd.address_2)
    setCustomerCity(selectAdd.city)
    setCustomerState(selectAdd.state)
    setCustomerZip(selectAdd.zip)
    setCustomerPhone(phone)
 
    setStep(4);
    set4CrossedSet(true)
    }
}
  const addNewAddess=()=>{

    setNewAddress(true);
    set4CrossedSet(true)
    sethasValidAddress(false)
  }

  const handleAddressChange = (e) =>{
    const { name, value } = e.target;
    setAddressFormData({
      ...formAddessData,
      [name]: value, // Update the respective field's value
    });
   
   
  
};
const [isOpen, setIsOpen] = useState(false);
const toggleDropdown = () => {
   // alert(33);
  setIsOpen(true);
};
const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Handle mouse enter and leave events
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };
const validateAddressForm = () => {
    let validationErrors = {};
    if (!formAddessData.name) validationErrors.name = "Full Name is required.";
    if (!formAddessData.email) validationErrors.email = "Email is required.";
    if (!/^\S+@\S+\.\S+$/.test(formAddessData.email)) validationErrors.email = "Email format is invalid.";
    if (!formAddessData.zip) validationErrors.zip = "Pincode is required.";
    if (!formAddessData.city) validationErrors.city = "City is required.";
    if (!formAddessData.state) validationErrors.state = "State is required.";
    if (!formAddessData.address_1) validationErrors.address_1 = "Address 1 is required.";

    return validationErrors;
  };
const handleAddressSubmit = async(e) => {
    e.preventDefault(); // Prevent page reload
    const validationErrors = validateAddressForm();
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        setErrors({});
       // console.log("Form data submitted:", formAddessData);
        try{
          
          const response = await postData(`${Config.BASE_URL}/${Config.NEW_ADDRESS}`, formAddessData, "ADD_NEW_ADDRESS");
          if (response.status === true || response.code === 200) {
                setCustomerName(formAddessData.name);
                setCustomerAddress1(formAddessData.address_1)
                setCustomerAddress2(formAddessData.address_2)
                setCustomerCity(formAddessData.city)
                setCustomerState(formAddessData.state)
                setCustomerZip(formAddessData.zip)
                setCustomerPhone(phone)
                setStep(4);
               
                set4CrossedSet(true)

          }
          
         
       

      }catch(error){
          console.log(error)
          
      }
      }
};


const editAddress=async(value)=>{
    
    
    const edit = JSON.parse(value);
    setAddressFormData({
        pid:edit.pid,
        name:edit.name,
        email:edit.email,
        zip: edit.zip,
        city: edit.city,
        state: edit.state,
        address_1: edit.address_1,
        address_2: edit.address_2,
        phone: edit.phone,
        address_type: edit.address_type,

     });
     setNewAddress(true);
     sethasValidAddress(false)
     set4CrossedSet(true)
    
  }
const editAddressSubmit=async(e)=>{
   
    e.preventDefault(); // Prevent page reload
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      //console.log(localStorage.getItem('phone'))
      // Submit the form
     // console.log("Form data submitted:", formData);

      try{
        
        const response = await postData(`${Config.BASE_URL}/${Config.NEW_ADDRESS}`, formAddessData, "EDIT_ADDRESS");
        if (response.status === true || response.code === 200) {
        
        setCustomerName(formAddessData.name);
        setCustomerEmail(formAddessData.email);
        setCustomerAddress1(formAddessData.address_1)
        setCustomerAddress2(formAddessData.address_2)
        setCustomerCity(formAddessData.city)
        setCustomerState(formAddessData.state)
        setCustomerZip(formAddessData.zip)
        setCustomerPhone(phone)
       // const addressList = JSON.parse(localStorage.getItem('preaddress'));
       const phonm=localStorage.getItem('phone');
       localStorage.getItem('pii');
        const filter='phone';
        const endurl= `${Config.BASE_URL}/${Config.GET_ADDRESS}?${filter}=${phonm}`;
       
       const getAddress=await getData(endurl, 'Featch Address');
        checkUserAddress(getAddress.data);
       //console.log(getAddress.data);
       if (getAddress.status === true || getAddress.code === 200) {
           if(getAddress.data.length > 0){
           localStorage.setItem('preaddress',  JSON.stringify(getAddress.data));
           setStep(3);
           setNewAddress(false);
           sethasValidAddress(true)
           set4CrossedSet(true)
           }
        }
     
     
        }
       
    }catch(error){
        setErrors({});
        
        const myArray = error.message.split(",");
        SetError(error.message);
        

        
    }
    }
  }
const updatePhone=()=>{
        setActiveTab(true)
        setStep(1);
      }
const  backAddress =()=>{
        setNewAddress(false);
        sethasValidAddress(true)
    }

    const goToStep = (specificStep) => {
   
        if(specificStep===5 || specificStep===6){
            setStep(3);
            setNewAddress(true);
            sethasValidAddress(false)
        }else{
            setStep(specificStep);
        }
        
    
      };
const shippingchrage=()=>{
    if (!Array.isArray(cartitems)) return 0; // Fallback in case cartitems is not an array
    
    return cartitems.reduce((total, product) => {
      const price = parseFloat(product.product_sale_price || product.product_regular_price);

      return total + (price * product.product_quantity);
    }, 0);

}
//dstepper-block active
//crossed
const handlePayment = async (event) => {
    event.preventDefault(); 
  setLoading(true);

  try {

    const orderId = `ORDER_${new Date().getTime()}`;
   const amount =finalamount; //1.00 // In paise (100.00 INR)cartamount
    const request ={
      "order_id": orderId,
      "amount": amount,
      "gateway": "phonepe",
      "seller_id": storeId,
      "redirect_url":"https://webhook.site/e875700a-c73e-4819-a46b-e366f2c119b3",
      "x_browser_fingerprint":fingerprint,
      "user_agent": navigator.userAgent,
      "x_merchant_domain":"https: //uat.nimbuspost.com",
      "x_merchant_ip":"182.79.98.221"
  }
 // console.log(orderId);
    // Call the backend to create an order and get the deepLink
    const response = await paymentPostData(`${Config.PAYMENT_BASE_URL}/${Config.PAYMENT_CREATE}`,request,'PAYMENT GATEWAY CHOOSE');

   // console.log(response);
    if (response.status === true || response.code === 200) {
        const tokenUrl = response.data.payment_url;//"https://your-server.com/api/generate-token"; // Replace with your token URL
       // alert(tokenUrl)
        handleTransaction(tokenUrl,orderId,storeId);
    }
 
   
  } catch (error) {
      console.log(error)
}
}
const handleTransaction = async(tokenUrl,orderId,storeId) => {

// const tokenUrl = response.data.payment_url;//"https://your-server.com/api/generate-token"; // Replace with your token URL

// Ensure PhonePeCheckout is available
if (window.PhonePeCheckout) {
  window.PhonePeCheckout.transact({
    tokenUrl,
    type: "IFRAME", // Use IFRAME type for embedded checkout experience
    callback: (status, response) => {
        paymentstate(orderId,storeId);
        createOder('PhonePe');
        navigate('/success', { state: { order:  orderId, seller:storeId, links:links }});
           // console.log(response);
    //   if (status === "SUCCESS") {
    //       console.log(response);
    //     console.log("Transaction Successful:", response);
    //   } else if (status === "FAILURE") {
    //     console.error("Transaction Failed:", response);
    //   } 
    //   else {
    //     console.warn("Transaction Status:", status, response);
    //   }
    },
  });
} else {
  console.error("PhonePeCheckout is not available.");
}
};
const [paymentid, setPaymentId] = useState('');
const [paymentstatuss, SetPaymentStatus] = useState('');
const paymentstate=async(orderId,storeId)=>{
    const endpoint = 'https://payment.bp.nimbuspost.com/api/v1/payment/status';
    const playload={
        "order_id":orderId,//"423534345654654",//order,
        "seller_id":storeId,// "1",//seller,
     
      };
      
      try{
        const response = await paymentstatus(endpoint, playload, "PAYMENT STATUS");
        if (response.status === true || response.code === 200) {
            const paymentid = response.data.payment_id;
            setPaymentId(paymentid)
            const paymentstatus = response.data.payment_status;
            SetPaymentStatus(paymentstatus);
        }
      }catch(error){
        console.log(error);
      }
       
      
   
    };
  
  const createOder=async(method)=>{
   
  
    const orderData = {
        payment_method: method,
        payment_method_title: "Nimbuspost Checkout",
        set_paid: true,
        billing: {
          first_name: customername ? customername : '',
          last_name:'',
          country: "IND",
          address_1:customeraddress1 ? customeraddress1 : '',
          email: customeremail?customeremail:'',
          city: customercity? customercity: '',
          state: customerstate? customerstate: '',
          postcode: customerzip ? customerzip : '',
          phone: phone ? phone: ''
        },
        shipping: {
          first_name: customername ? customername : '',
          last_name: "",
          address_1: customeraddress1 ? customeraddress1 : '',
          address_2: customeraddress2 ? customeraddress1 : '',
          city: customercity? customercity: '',
          state: customerstate? customerstate: '',
          postcode: customerzip ? customerzip : '',
          country: "IND"
        },
        line_items: orderslineitems ? orderslineitems : '',
        shipping_lines: [
          {
            method_id: "flat_rate",
            method_title: "Flat Rate",
            total: cartamount ? cartamount : 0 
          }
        ],
      
      };
  
      const response = await createWooCommerceOrder(siteurl,sitekey,sitesec, JSON.stringify(orderData),'PAYMENT_CREATE');
      //const array1 = json.parse(cartialltems);
      const itemso ={
        cartialltems, ...response    
      }
     // console.log(itemso)
     
      const setRequest={
        storeid: storeId,
        merchant_id: merchantId,
        //"channel_id":2,
        status:"complete",
        checkout_configrations:{
            item_details:JSON.stringify(itemso),
        },
    };
    const checkout = await checkoutpostData(`${Config.SETTING_BASE_URL}/${Config.SETTING_CHECKOUT}`, setRequest, "CHECKOUT");


      return true;
     // console.log(response)
      
}

const handleToggle = (index) => {
    setVisibleModes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

 
// Razorpay constructor
const rozorpaypayment= async (event)=>{
    event.preventDefault(); 
    var options = {
        "key": "rzp_test_oauth_PBhKUg1a04MwVg",
        "amount": finalamount,  // The same amount used when creating the order
        "currency": "INR",
       // "name": "test",
        //"description": "Test Transaction",
        "order_id": "order_PBhNKRDn13W7Eh",  // The order ID you received from Razorpay
        "handler": function (response){
           // console.log('hello',response,'hiii')
            navigate('/ordersuccess', { state: { order: response.razorpay_payment_id, seller:storeId, links:links }});
            // You will get razorpay_payment_id, razorpay_order_id, razorpay_signature
            console.log(response.razorpay_payment_id);
            console.log(response.razorpay_order_id);
            console.log(response.razorpay_signature);
            // Send payment_id, order_id, and signature to your backend server for verification
        },
        "prefill": {
          "name": customername ? customername : '',
         // "email": "customer@example.com",
          "contact": phone ? phone: ''
        },
        "theme": {
          "color": "#F37254"
        }
      };
      var rzp1 = new Razorpay(options);
      rzp1.open();
    }

  const handleRazorpayPayment = () => {
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
        try{
            const orderId = `${new Date().getTime()}`;
            const amount =finalamount; //1.00 // In paise (100.00 INR)cartamount
            const request ={
             "order_id": orderId,
             "amount": amount,
             "gateway": "razorpay",
             "seller_id": storeId,
            // "redirect_url":"https://webhook.site/e875700a-c73e-4819-a46b-e366f2c119b3",
            "redirect_url": window.close("/successfull"),
             "x_browser_fingerprint":fingerprint,
             "user_agent": navigator.userAgent,
             "x_merchant_domain":"https: //uat.nimbuspost.com",
             "x_merchant_ip":"182.79.98.221"
         }
         const response = await paymentRozarpay(`${Config.PAYMENT_RAZOR}/${Config.PAYMENT_RAZAOR_CREATE}`,request,'PAYMENT GATEWAY CHOOSE');
        //  console.log(response);
         const payRid = response.data.payment_id;
         var options = {
            "key": response.data.public_token,
            "amount": amount,//finalamount,  // The same amount used when creating the order
            "currency": "INR",
            "name": "NP Checkout",
            //"description": "Test Transaction",
            "order_id": payRid,  // The order ID you received from Razorpay
            "handler": async function (response){
                //console.log(response)
                paymentstate(orderId,storeId);
                createOder('Razorpay');
                navigate('/successfull', { state: { order:orderId, seller:storeId, amounts:amount, payid:response.razorpay_payment_id, links:links }});
               
            },
            "prefill": {
                "name": customername ? customername : '',
         // "email": "customer@example.com",
          "contact": phone ? phone: ''
            },
            "theme": {
              "color": "#F37254"
            }
          };

          var rzp1 = new Razorpay(options);
          rzp1.open();

        }catch(error){
            console.log(error)
        }
    }, 2000);
  };
  const renderGatewayButton = (gateway, label, onClickHandler, image) => (
    <div className="col-md-3" style={{ marginTop: '10px' }}>
    <button onClick={onClickHandler} disabled={loading} className="paymentgateway">
      <img src={image} alt={label} />
   
    </button>
  </div>
  );
  const onClose=async()=>{
    alert(999);
    localStorage.removeItem("balc");

    // Make the AJAX request using axios
    axios
      .post(`${siteurl}/?wc-ajax=empty_cart_item`)
      .then((response) => {
        console.log(response)
        // Reload the page on success
        window.location.reload(true);
      })
      .catch((error) => {
        console.error("Error emptying cart:", error);
      });
  }

        return (
              <main id="content" role="main" className="checkout-page mt-5">
                    <div className="container">
                        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body pt-0 pb-0">
                                    <div className="row">
                                        <div className=" mb-lg-0">
                                        {/* <button
      type="button"
      className="close"
      data-dismiss="modal"
      aria-label="Close"
      onClick={onClose}
    >
      <span aria-hidden="true">&times;</span>
    </button> */}
                                        </div>
                                        <div className="" id="msform">
                                            <div className="bs-stepper wizard-numbered">
                                                <div className="headers">
                                                     <div className="progressbar_head">
                                                     <img
                                                            src={companylogo || "assets/images/logo.jpg"}
                                                            className="logo_height"
                                                            alt="Company Logo"
                                                            onError={(e) => { e.target.src = "assets/images/logo.jpg"; }}
                                                            />
                                                            <div className="progressbar">
                                                                 <div className="bs-stepper-header step-container d-flex justify-content-between">
                                                                 <div className={crossedset ? 'step crossed' : 'step'} data-target="#mobile-info">
                                                                    <button type="button" className="step-trigger">
                                                                        <span className="bs-stepper-label"><i
                                                                                className="fa-solid fa-check"></i>
                                                                            <small>1</small></span>
                                                                        <span className="bs-stepper-title">Mobile</span>
                                                                    </button>
                                                                </div>
                                                                <div className={crossed4set ? 'step  crossed' : 'step'} data-target="#address-info">
                                                                    <button type="button" className="step-trigger">
                                                                        <span className="bs-stepper-label"><i
                                                                                className="fa-solid fa-check"></i>
                                                                            <small>2</small></span>
                                                                        <span className="bs-stepper-title">Address</span>
                                                                    </button>
                                                                </div>
                                                                <div className="step last" data-target="#payment">
                                                                    <button type="button" className="step-trigger">
                                                                        <span className="bs-stepper-label"><i
                                                                                className="fa-solid fa-check"></i>
                                                                            <small>3</small></span>
                                                                        <span className="bs-stepper-title">Payment</span>
                                                                    </button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                       </div>
                                                       
                                    
                                                </div>

                                                <div className="step_content">
                                                    <div className="cart-wrap-scroller">
                                                        <div id="basicsAccordion" className="mb-0 mt-3 orderaccordin d-block">
                                                            <div className="card mb-0 border-top-0 border-left-0 border-right-0 border-color-1 rounded-15">
                                                                <div className="card-header card-collapse bg-transparent-on-hover border-0" id="basicsHeadingOne">
                                                                    <h5 className="mb-0">
                                                                        <button type="button" className="Order_Summary pl-2 align-items-center gap-5 px-0 btn btn-link btn-block d-flex card-btn py-3 font-size-25 border-0"
                                                                            data-toggle="collapse"
                                                                            data-target="#basicsCollapseOner"
                                                                            aria-expanded="true"
                                                                            aria-controls="basicsCollapseOner">
                                                                            <i className="fa-regular fa-cart-shopping"></i>
                                                                            <span>Order Summary</span>

                                                                            <span className="card-btn-arrow">
                                                                                <i
                                                                                    className="fas fa-chevron-down text-gray-90 font-size-18"></i>
                                                                            </span>

                                                                            <span className="price">₹ {finalamount?finalamount:calculateTotal().toFixed(2)}</span>

                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id="basicsCollapseOner" className="collapse " aria-labelledby="basicsHeadingOne" data-parent="#basicsAccordion">
                                                                        <div className="card-body pl-0 pr-0 pb-2">
                                                                            <div className="list-unstyled px-0 pt-0 product_list">
                                                                                <div className="product-detail">
                                                                                {cartitems && cartitems.length > 0 ? (
                                                                                    cartitems.map((product, index) => (<ul className="list-unstyled" key={index}>
                                                                                   
                                                                                   
                                                                                        <li className="product_image">
                                                                                            <img className="img-fluid" src={product.product_image} alt="Image Description" />
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="product-details">
                                                                                                <div className="title">{product.product_title}
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                <div className="col-sm-6">  <span className="quantity">Quantity:  {product.product_quantity}</span></div>
                                                                                                <div className="col-sm-6"> <span className="price">Price: ₹{product.product_price*product.product_quantity}.00</span></div>
                                                                                                </div>
                                                                                               
                                                                                              
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                      ))
                                                                                    ) : (
                                                                                        <ul className="list-unstyled">
                                                                                        <li className="product_image">
                                                                                            <img className="img-fluid" src="assets/images/img1.jpg" alt="Image Description" />
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="product-details">
                                                                                                <div className="title">Squooshy Slides for Men: Olive-Black - UK 11
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-6"> <span className="quantity">Quantity:  1</span></div>
                                                                                                    <div className="col-sm-6"> <span className="price">Price: ₹719.10</span></div>
                                                                                                </div>
                                                                                               
                                                                                               
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="order-summary-details">
                                                                                <ul>
                                                                                    <li className="subtotal">
                                                                                        <span>Subtotal</span><span>₹{calculateTotal().toFixed(2)}</span>
                                                                                    </li>
                                                                                    <li className="coupon-discount">
                                                                                        <span>Automatic Discount</span> <span>-
                                                                                            ₹ {discount?discount:0}.00</span></li>
                                                                                    <li className="shipping">
                                                                                        <span>Shipping</span> <span>₹{shipppingcgarge?shipppingcgarge:0}.00</span>
                                                                                    </li>
                                                                                    <li className="to-pay"><span>To
                                                                                            Pay</span> <span>₹{finalamount?finalamount:calculateTotal().toFixed(2)}</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bs-stepper-content">
                                                               
                                                                  
                                                        {step === 1  && (<div id="mobile-info" className={activetab ? 'content dstepper-block active' : 'content'}>    

                                                                         <form onSubmit={mobileNumberSubmit}>  
                                                                            <div className="col-md-12 ml-auto mr-auto">
                                                                                <div className="newsletter-steps step1 mb-4">
                                                                                    <h5 className="fs-subtitle mt-5 mb-3">Enter Your Phone Number</h5>
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control ${errors.phone ? 'u-has-error' : ''}`}
                                                                                        name="phone"
                                                                                        placeholder="Enter phone number"
                                                                                        value={formData.phone}
                                                                                        onChange={handleInputChange}
                                                                                        minLength={10}
                                                                                        maxLength={10}
                                                                                    />
                                                                                     {errors.phone && <p className="text-danger">{errors.phone}</p>}
                                                                                    <button type="submit" className="action-button mt-5">Send OTP</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                     
                                                                    </div>)}
                                                        {step === 2  && (<div id="mobile-info" className={activetab ? 'content dstepper-block active' : 'content'}>    

                                                                        <div className="newsletter-steps step2 mb-4" style={{display:'block'}}>
                                                                            <h5 className="fs-subtitle mt-5">Please Enter the one time password</h5>
                                                                            <p>Sent to +91  ******* {getLastFourDigits(mobile)} <small onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></small></p>
                                                                           
                                                                            <form onSubmit={optVerify}>
                                                                                <OTPInput length={6} onChange={handleOtpChange} />
                                                                                {error.message}
                                                                                {error && <p className="text-danger">{error}</p>}
                                                                                <button type="submit" className="btn action-button btn-primary next-step" >Verify OTP</button>
                                                                            </form>
                                                                        </div>
                                                                       
                                                                    </div>)}

                                                        {step === 3  && (<div id="address-info"className={activetab ? 'content dstepper-block active' : 'content'}>
                                                            {hasValidAddress && (<div className="selectadd">
                                                                
                                                                   
                                                                            <div className="text-left d-flex mt-0 mb-3">
                                                                                <div className="select_address">
                                                                                    <h5 className="section-title mb-0 pb-1 font-size-20" style={{border: 'none', background: 'transparent'}}>Select Address</h5>
                                                                                    <p className="mb-0 ml-auto"><i className="fa-regular fa-mobile"></i>*****{getLastFourDigits(mobile)} <a  onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></a></p>
                                                                                </div>
                                                                                <div className="add_address mb-0 ml-auto pt-3">
                                                                                    <a  onClick={addNewAddess} className="addaddress fw-bold text-dark" rel="addnewaddress"><i  className="fa-regular fa-plus pl-2"></i> Add New Address</a>
                                                                                </div>
                                                                            </div>
                                                                            <form onSubmit={customerAddress}>
                                                                                    <div className="grid select_address_scroll">
                                                                                            {addressList && addressList.length > 0 ? (
                                                                                                addressList.map((item,index) => (
                                                                                                    
                                                                                                
                                                                                                    <label className="card pt-3 pb-3 text-left mb-2 pl-6 gray_bg" key={index}>
                                                                                                        {(item.name !== '' && item.name !== null && item.email !== '' && item.email !== null) ? (
                                                                                                            <>
                                                                                                            <input
                                                                                                                name="address"
                                                                                                                className="radio"
                                                                                                                type="radio"
                                                                                                                value={item.pid}

                                                                                                                checked={selectedOptions.address === item.pid}
                                                                                                                onChange={handleOptionChange}
                                                                                                            />
                                                                                                        
                                                                                                        <p className="mb-0"><strong>{item.name}</strong><br />{item.address_1}, {item.address_2}, {item.city}, {item.state}, {item.zip}</p>
                                                                                                        <ul className="action_btn">
                                                                                                                <li><a onClick={() => editAddress(JSON.stringify(item))} className="addaddress" rel="addnewaddress"><i className="fa-solid fa-pen-to-square"></i></a></li>
                                                                                                                {/* <li><a href="#"><i class="fa-regular fa-trash"></i></a></li> */}
                                                                                                            </ul>
                                                                                                            </>
                                                                                                            ) : null}
                                                                                                    </label>
                                                                                                

                                                                                                ))
                                                                                                ) : (
                                                                                                    <label></label>
                                                                                                )}
                                                                                         {errors.address && <p className="error">{errors.address}</p>}

                                                                                        <div className="plans">
                                                                                        <div className="title">Shipping Method</div>
                                                                                                <label className="plan basic-plan" htmlFor="basic">
                                                                                                            <input 
                                                                                                                className="radio" 
                                                                                                                type="radio" 
                                                                                                                name="plan"
                                                                                                                id="basic"
                                                                                                                value="standard"
                                                                                                                checked={selectedOptions.plan === 'standard'}
                                                                                                                onChange={handleOptionChange}
                                                                                                                />
                                                                                                                <div className="plan-content">
                                                                                                                <div className="plan-details">
                                                                                                                    <span>Standard + ₹ {standardcharge}</span>
                                                                                                                    {eodstandard && (<p>{eodstandard}</p>)} 
                                                                                                                </div>
                                                                                                            </div>
                                                                                                </label>
                                                                                                <label className="plan complete-plan" htmlFor="complete">
                                                                                                    <input 
                                                                                                            className="radio" 
                                                                                                            type="radio" 
                                                                                                            id="complete"
                                                                                                            name="plan"
                                                                                                            value="express"
                                                                                                        checked={selectedOptions.plan === 'express'}
                                                                                                        onChange={handleOptionChange}  />
                                                                                                    <div className="plan-content">
                                                                                                        <div className="plan-details">
                                                                                                            <span>Express + ₹ {expresscharge}</span>
                                                                                                            {eodexpress &&(<p>{eodexpress}</p>) } 
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </label>
                                                                                                {errors.plan && <p className="error">{errors.plan}</p>}
                                                                                    </div>
                                                                           
                                                                                    <button type="submit" className="action-button btn btn-primary  btn-next">Save  and Continue</button>  

                                                                                    </div>
                                                                                  
                                                                                </form>
                                                                        </div>
                                                                
                                                                
                                                            )}
                                                            {isNewAddress && (
                                                                        <div className="addnewaddress mt-4 " style={{display: 'block'}}>
                                                                            <div className="text-left d-flex">
                                                                                <div className="d-block">
                                                                                    <h5 className="section-title mb-0 pb-1 font-size-20" style={{border: 'none', background: 'transparent'}}> Add New Address</h5>
                                                                                        <div className="text-left mt-2">
                                                                                            <a className="addaddress" onClick={backAddress} rel="selectadd"><i className="fa-solid fa-square-arrow-left"></i> Back</a>
                                                                                        </div>
                                                                                </div>
                                                                                <p className="mb-0 ml-auto pt-3"><i className="fa-regular fa-mobile"></i> ******* {getLastFourDigits(mobile)} <a onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></a> </p>
                                                                            </div>
                                                                           <p>{error}</p> 
                                                                            <div className="border p-3 mt-2 pt-0 pb-0">
                                                                                <div className="row mt-3">
                                                                                    <div className="col-md-6">
                                                                                        <div className="js-form-message mb-3">
                                                                                        <input type="text" className="form-control" name="name" placeholder="Full Name" aria-label="Jack" required  value={formAddessData.name}
          onChange={handleAddressChange} />
           {errors.name && <small className="text-danger">{errors.name}</small>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="js-form-message mb-3">
                                                                                        <input type="email" className="form-control" name="email" placeholder="Email." aria-label="Email" required value={formAddessData.email}
          onChange={handleAddressChange} />
            {errors.email && <small className="text-danger">{errors.email}</small>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4"> 
                                                                                        <div className="js-form-message mb-3">
                                                                                        <input type="text" className="form-control" name="zip" placeholder="Pincode" aria-label="Pincode" required  value={formAddessData.zip}
          onChange={handleAddressChange} />
            {errors.zip && <small className="text-danger">{errors.zip}</small>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                         <div className="js-form-message mb-3">
                                                                                         <input type="text" className="form-control" name="city" placeholder="City" aria-label="City" required   value={formAddessData.city}
          onChange={handleAddressChange}/>
            {errors.city && <small className="text-danger">{errors.city}</small>}
                                                                                        </div>     
                                                                                    </div>
                                                                                     <div className="col-md-4">
                                                                                        <div className="js-form-message mb-3">
                                                                                        <input type="text" className="form-control" name="state" placeholder="State" aria-label="State" required  value={formAddessData.state}
          onChange={handleAddressChange}/>
            {errors.state && <small className="text-danger">{errors.state}</small>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className="js-form-message mb-3">
                                                                                        <input type="text" className="form-control" name="address_1" placeholder="Address 1" aria-label="Address 1" required   value={formAddessData.address_1}
          onChange={handleAddressChange}/>
            {errors.address_1 && <small className="text-danger">{errors.address_1}</small>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className="js-form-message mb-3">
                                                                                        <input type="text" className="form-control" name="address_2" placeholder="Address 2" aria-label="Address 2"  value={formAddessData.address_2}
          onChange={handleAddressChange}/>
              <input type="hidden" className="form-control" name="phone" placeholder="phone" aria-label="phone"  value={formAddessData.phone?formAddessData.phone:`${localStorage.getItem('phone')}`}
          onChange={handleAddressChange}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className="js-form-message mb-3">
                                                                                            <p className="form-label text-left mb-2"> Save address as :<span className="text-danger">*</span></p>
                                                                                            <div className="d-flex">
                                                                                                <div className="custom-control custom-radio mr-5">
                                                                                                <input type="radio" className="custom-control-input" id="home" name="address_type"   value="home"  checked={formAddessData.address_type === 'home'}
              onChange={handleAddressChange}/>
                                                                                                    <label className="custom-control-label form-label" htmlFor="home">  Home</label>
                                                                                                </div>
                                                                                                <div className="custom-control custom-radio mr-5">
                                                                                                <input type="radio" className="custom-control-input" id="work" name="address_type" value="work"
              checked={formAddessData.address_type === 'work'} onChange={handleAddressChange}/>
                                                                                                    <label className="custom-control-label form-label" htmlFor="work">Work</label>
                                                                                                </div>
                                                                                                <div className="custom-control custom-radio">
                                                                                                <input type="radio" className="custom-control-input" id="other" name="address_type"  value="other"
              checked={formAddessData.address_type === 'other'} onChange={handleAddressChange}/>
                                                                                                    <label className="custom-control-label form-label" htmlFor="other">Other</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                                <button type="button" className="action-button btn btn-primary  btn-next"  onClick={editAddressSubmit}>Save and Continue</button>
                                                                            </div>
                                                                        </div>
                                                             )}
                                                                    </div>)}

                                                        {step === 4  && (<div id="payment" className={activetab ? 'content dstepper-block active' : 'content'}>
                                                                        <div className="mb-4 text-left d-flex">
                                                                            <h5 className="section-title mb-0 pb-1 font-size-20" style={{border: 'none',  background: 'transparent'}}>Select Payment Mode</h5>
                                                                            <p className="mb-0 ml-auto"><i className="fa-regular fa-mobile"></i> ******* {getLastFourDigits(mobile)} <a  onClick={updatePhone}><i className="fa-regular fa-pen-to-square pl-2"></i></a></p>
                                                                        </div>
                                                                        <div className="card p-3 text-left mb-3 gray_bg">
                                                                            <h6><strong>Delivering To:</strong></h6>
                                                                            <p className="mb-0"><strong>{customername}</strong><br />{customeraddress1}, {customeraddress2}, {customercity}, {customerstate}, {customerzip}</p>
                                                                            
                                                                            <div className="User-area">
                                                                                <div className="User-avtar" onClick={toggleDropdown}><i className="fa-solid fa-ellipsis"></i></div>
                                                                                {isOpen && (<ul className="User-Dropdown U-open">
                                                                                        <li><a  className="btn-prev" onClick={() => goToStep(3)} disabled={step === 1 && step === 2}>Change Address</a></li>
                                                                                        <li><a className="btn-prev" onClick={() => goToStep(5)}>Edit Address</a></li>
                                                                                        <li><a className="btn-prev" onClick={() => goToStep(6)}>Add Address</a></li>
                                                                       
                                                                                </ul>)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row payments">

                                                                        <>
      {settingpaymod.map((mode, index) => (
        <div key={index} className="col-md-12">
          {/* <button onClick={() => handleToggle(index)}> */}
          {mode.primary_gateway === 'Phonepe' &&(<button onClick={handlePayment} disabled={loading}>
                {visibleModes[index] ? (
                    <>
                    <img
                        src={`assets/images/${mode.payment_mode.toLowerCase()}.png`}
                        alt={mode.payment_mode}
                    />
                    {mode.payment_mode.toUpperCase()}
                    </>
                ) : (
                    <>
                    <img
                        src={`assets/images/${mode.payment_mode.toLowerCase()}-hidden.png`}
                        alt={mode.payment_mode}
                    />
                    {mode.payment_mode.toUpperCase()}
                    </>
                )}
        </button>)}
        {mode.primary_gateway === 'Razorpay' &&(<button onClick={handleRazorpayPayment} disabled={loading}>
                {visibleModes[index] ? (
                    <>
                    <img
                        src={`assets/images/${mode.payment_mode.toLowerCase()}.png`}
                        alt={mode.payment_mode}
                    />
                    {mode.payment_mode.toUpperCase()}
                    </>
                ) : (
                    <>
                    <img
                        src={`assets/images/${mode.payment_mode.toLowerCase()}-hidden.png`}
                        alt={mode.payment_mode}
                    />
                    {mode.payment_mode.toUpperCase()}
                    </>
                )}
        </button>)}
          {/* {visibleModes[index] && (
                <>
                {mode.primary_gateway === 'Phonepe' &&
                    renderGatewayButton('Phonepe', 'PhonePe', handlePayment, 'assets/images/smallpe.png')}
                {mode.secondary_gateway === 'Phonepe' &&
                    renderGatewayButton('Phonepe', 'PhonePe', handlePayment, 'assets/images/smallpe.png')}
                {mode.primary_gateway === 'Razorpay' &&
                    renderGatewayButton('Razorpay', 'Razorpay', handleRazorpayPayment, 'assets/images/razor_pay.png')}
                {mode.secondary_gateway === 'Razorpay' &&
                    renderGatewayButton('Razorpay', 'Razorpay', handleRazorpayPayment, 'assets/images/razor_pay.png')}
                </>
          )} */}
        </div>
      ))}
    </>
   
{/*                          
                                                                    { paymode.includes('emi') &&( <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/emi.png" />EMI
                                                                                <i className="fa-solid fa-caret-right text-right" style={{marginLeft: '240px'}}></i></button>
                                                                                
                                                                            </div>)}
                                                                            { paymode.includes('bnpl') &&( <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/p1.png" />BNPL
                                                                                <i className="fa-solid fa-caret-right text-right" style={{marginLeft: '210px'}}></i></button>
                                                                                
                                                                            </div>)}
                                                                            { paymode.includes('net banking') &&( <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/p3.png" />Net Banking 
                                                                                <i className="fa-solid fa-caret-right text-right" ></i></button>
                                                                                
                                                                            </div>)}
                                                                            { paymode.includes('cards') &&( <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/p2.png" /> Credit /Deditcard 
                                                                                <i className="fa-solid fa-caret-right text-right" ></i></button>
                                                                                
                                                                            </div>)}
                                                                            { paymode.includes('wallets') &&( <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/p4.png" /> Wallets
                                                                                <i className="fa-solid fa-caret-right text-right" ></i></button>
                                                                                
                                                                            </div>)}
                                                                            { paymode.includes('upi') &&( <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/p2.png" /> UPI
                                                                                <i className="fa-solid fa-caret-right text-right" ></i></button>
                                                                                
                                                                            </div>)} */}
                                                                            
                                                                            

                                                                            {/* <div className="col-md-12">
                                                                                <button  onClick={handlePayment} disabled={loading}><img src="assets/images/PhonePe.png" />
                                                                                <i className="fa-solid fa-caret-right text-right" style={{marginLeft: '180px'}}></i></button>
                                                                                
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <a href="#"><img src="assets/images/p2.png" /> Credit /Deditcard 
                                                                                <i className="fa-solid fa-caret-right text-right"></i></a>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <a href="#"><img src="assets/images/p3.png" />Net Banking 
                                                                                <i className="fa-solid fa-caret-right text-right"></i></a>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <a href="#"><img src="assets/images/p4.png" />Cash On delivery 
                                                                                <i className="fa-solid fa-caret-right text-right"></i></a>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>)}
                                                        
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="footer_con">
                                                    <div className="powered_by">
                                                        Powered By <img src="assets/images/nimbus_logo.jpg" />
                                                    </div>
                                                    <div className="text-right footer_content">
                                                        <ul>
                                                        {links.shipping_policy && <li><a href={links.shipping_policy} target="_blank" rel="noopener noreferrer">Shipping Policy</a></li>}
                                                        {links.refund_policy  && <li> <a href={links.refund_policy} target="_blank" rel="noopener noreferrer">Refund Policy</a></li> }
                                                        {links.privacy_policy && <li><a href={links.privacy_policy} target="_blank" rel="noopener noreferrer">Privacy policy</a></li>}
                                                           
                                                        </ul>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
          
        );
}
export default Checkout2;