import axios from "axios";
import Config from "../Config/Config"; 
import {logsPostData} from './Logs'
let PAYMENT_TOKEN = '';
let tokenExpiryTime = null; 

const generateToken = async () => {
    try {
        const end=`${Config.PAYMENT_RAZOR}/${Config.PAYMENT_TOKEN}`;
        //console.log(end);
      const response = await fetch(end, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:JSON.stringify({ username: 'Nimbuspostcheckout', password: 'demotest'}),
      });
    //  console.log(response.data);
   
      const data = await response.json();
      //console.log(data.data.data)
      //if (data.status && data.code === 200) {
        PAYMENT_TOKEN = data.data.data;
        //tokenExpiryTime = data.data.expiry; // Assuming the API returns an expiry timestamp
        return data;
     // }
      //throw new Error('Failed to generate token');
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  };
  const getValidToken = async () => {
     if (!PAYMENT_TOKEN) {
       await generateToken(); // Generate a new token if it's missing or expired
    }
     return PAYMENT_TOKEN;
   };
   
 const createGatewayApi = async () => {
     const validToken = await getValidToken();
     return axios.create({
       baseURL: Config.SETTING_BASE_URL,
       headers: {
         'Content-Type': 'application/json',
        // Authorization: `Bearer ${Config.SETTING_TOKEN}`,
        Authorization: `Bearer ${validToken}`,
       },
     });
   };

export const paymentPostData = async (endpoint, data, message)=>{
    try {
        const apiInstance = await createGatewayApi();
        //console.log(endpoint);
        const response = await apiInstance.post(endpoint, data);
       // console.log(response);
        if (response.data.status === true || response.data.code === 200) {
          eventlogs(localStorage.getItem('store_id'),message, "Select payment gateway");
        return response.data; // Return the response data
        }
      } catch (error) {
        console.error('Error posting data:', error?.response?.data || error.message);
        throw error;
      }
}

export const paymentstatus = async(endpoint, data, message)=>{
  try {
    const apiInstance = await createGatewayApi();
   
    const response = await apiInstance.post(endpoint, data);
    
    return response.data; // Return the response data
    
  } catch (error) {
    console.error('Error posting data:', error?.response?.data || error.message);
    throw error;
  }
}

export const getpaymentmode =async(endpoint, message)=>{
  try {
    const apiInstance = await createGatewayApi();
   
    const response = await apiInstance.get(endpoint);
    
    return response.data; // Return the response data
    
  } catch (error) {
    console.error('Error posting data:', error?.response?.data || error.message);
    throw error;
  }

}

const eventlogs = async(pid, type, message)=>{
  const logs={
    "merchant_id":localStorage.getItem('merchant_id'),
     "store_id":localStorage.getItem('store_id'),
      "req_id":pid,
       "event_type":type,
       "message":message
}
const logged = await logsPostData(`${Config.LOGS_BASE_URL}/${Config.LOGS_EVENT}`,logs);


}
