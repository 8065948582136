import React, { useState } from "react";

const OTPInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    if (value) {
      otp[index] = value;
      setOtp([...otp]);

      // Automatically focus the next input
      if (index < length - 1 && value) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }

    onChange(otp.join(""));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      otp[index] = "";
      setOtp([...otp]);
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
      {otp.map((data, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength="1"
          value={otp[index]}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            fontSize: "18px",
          }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
